import { Grid, useMediaQuery, Box } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormikHelpers, validateYupSchema } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormValues, FormWizard } from "../components/FormWizard";
import { PatientForm, validatePatientFormData } from "../components/patientForm/PatientForm";
import { TreatmentSelect, validateTreatmentSelection } from "../components/treatments/TreatmentSelect";
import {
  AppointmentConfirmation,
  validateFormConfirmation as validateFormConfirmationAppointment,
} from "./appointmentBooking/AppointmentConfirmation";
import { DateTimePicker, validateTreatmentStartTime } from "./appointmentBooking/DateTimePicker";
import { PhoneVerification, validatePhoneVerification } from "../components/phoneVerification/PhoneVerification";
import { getVerificationCodeUUID } from "../modules/phoneVerification/selectors";
import { DateTimePickerMobile } from "./appointmentBooking/DateTimePickerMobile";
import { EmployeeSelectionStep, validateEmployeeSelectionStep } from "./appointmentBooking/EmployeeSelectionStep";
import { getAppointmentSlot } from "../modules/appointment/actions";
import { format, parse } from "date-fns";
import { useHistory, useLocation } from "react-router-dom";
import { getAllAvailableAppointmentBookingTreatments } from "../modules/treatments/selectors";
import { submitVerificationCode } from "../modules/phoneVerification/actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ContactPointSystem, ContactPointUse, HumanNameUse } from "../modules/appointment/types";
import { useBookingParamContext } from "../components/BookingParamContext";
import { Gender } from "../modules/patients/types";

// const useStyle = makeStyles((theme) => ({
//   componentContainer: { marginTop: theme.spacing(2), marginBottom: theme.spacing(4) },
// }));

interface AppointmentBookingFormProps {
  /** Force mobile view. Used for testing */
  isMobileView?: boolean;
  /** UUID of the orgUnit to book appointment from URL params */
  orgUnitUUID?: string;
}

/**
 * Wrapper component for the appointment booking process.
 * Data input is stored/retrieved from formik for the booking process, with data validation happening on an individual component level.
 */
export const AppointmentBookingForm = ({ isMobileView, orgUnitUUID }: AppointmentBookingFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  // const classes = useStyle();
  const location = useLocation();
  // Check if device has min-width of 600px
  const hasMinWidth = useMediaQuery("(min-width:600px)");
  let isDesktopDevice = false;
  const locale = i18n.language === undefined ? "de" : i18n.language;

  const aCat = useBookingParamContext();

  // Used for formatting dependent on locale
  const dateFormatLocale = {
    de: "dd.MM.yyyy",
    en: "yyyy-MM-dd",
  } as { [locale: string]: string };

  // Check if we're forcing mobile view for testing purposes, since testing library js-dom doesn't have min-width
  if (isMobileView !== undefined) {
    isDesktopDevice = !isMobileView;
    // Otherwise check if we have required min-width to be considered desktop device
  } else {
    isDesktopDevice = hasMinWidth;
  }

  /**
   * Time the user has last confirmed their waiting time.
   * Used to bring the user back to the confirmation step if too much time has elapsed.
   */
  const waitTimeConfirmedDate = useRef<null | number>(null);

  // Server response to verificaiton code request
  const verificationCodeUUID = useSelector(getVerificationCodeUUID);

  /**
   * Handle form submission
   * @param page page we're currently on
   * @param isLastPage
   * @param nextPage go to next page
   */
  const handleSubmission =
    (page: number, isLastPage: boolean, nextPage: () => void) =>
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      if (page === 2 && values.treatment?.employeeSelectable === false) {
        nextPage();
      }
      // phone verification code submission
      if (page === 4) {
        if (values.verificationCodeOTP === "" || verificationCodeUUID === null) {
          return;
        }
        dispatch(
          submitVerificationCode.request(
            {
              otp: values.verificationCodeOTP.replace(/\s+/g, ""),
              uuid: verificationCodeUUID,
            },
            {
              goToNextPage: nextPage,
              setSubmitting: formikHelpers.setSubmitting,
              history,
              pathName: location.pathname,
              isSlotReservation: false,
            }
          )
        );
      }
      if (!isLastPage) {
        //logic for correct page display/re direction on phone verification
        if (page !== 4) {
          nextPage();
        }
        formikHelpers.setSubmitting(false);
        formikHelpers.setTouched({});
        // Dispatch slot reservation on last page's submission
      } else {
        let aCatDecoded: string | undefined;
        let catCommentAddition: string | undefined;
        if (aCat !== null) {
          try {
            const decodedStr = window.atob(aCat);
            aCatDecoded = decodedStr;
          } catch (error) {}
          catCommentAddition = `Kategorie: ${
            aCatDecoded !== undefined ? window.decodeURIComponent(aCatDecoded) : "[keine ausgewählt]"
          }`;
        }
        let comment: string | null = null;
        if (values.clientAccountDetails.comment !== "" && catCommentAddition !== undefined) {
          comment = catCommentAddition.concat("\n", values.clientAccountDetails.comment);
        } else if (values.clientAccountDetails.comment !== "" && catCommentAddition === undefined) {
          comment = values.clientAccountDetails.comment;
        } else if (values.clientAccountDetails.comment === "" && catCommentAddition !== undefined) {
          comment = catCommentAddition;
        }

        dispatch(
          getAppointmentSlot.request(
            {
              clientAccountForInstitutionUserDTO: {
                acceptedPrivacyPolicy: values.acceptedPrivacyPolicy,
                clientAccountDetails: {
                  contactPoints: [
                    ...(values.clientAccountDetails.email !== ""
                      ? [
                          {
                            period: { start: moment().format("YYYY-MM-DD"), end: null },
                            rank: 1,
                            system: ContactPointSystem.EMAIL,
                            use: ContactPointUse.HOME,
                            value: values.clientAccountDetails.email,
                          },
                        ]
                      : []),
                  ],
                  dateOfBirth: format(
                    parse(values.clientAccountDetails.dateOfBirth!, dateFormatLocale[locale], new Date()),
                    "yyyy-MM-dd"
                  ),
                  names: [
                    {
                      family: values.clientAccountDetails.lastName,
                      given: [values.clientAccountDetails.firstName],
                      period: { start: moment().format("YYYY-MM-DD"), end: null },
                      prefix: null,
                      suffix: null,
                      text: null,
                      use: HumanNameUse.USUAL,
                    },
                  ],
                  gender: values.clientAccountDetails.gender as Gender,
                },
              },
              comment,
              startTime: values.slotProposal?.startTime,
              employeeCombination: values.slotProposal?.employeeCombinations ?? null,
              treatmentTypePublicId: values.treatment?.publicId,
              verificationCode: {
                otp: values.verificationCodeOTP.replace(/\s+/g, ""),
                uuid: verificationCodeUUID,
              },
            },
            // Meta object for passing data that we would otherwise not have access to inside of sagas.
            {
              history,
              setSubmitting: formikHelpers.setSubmitting,
              waitTimeConfirmedDate: waitTimeConfirmedDate.current,
              pathName: location.pathname,
            }
          )
        );
      }
    };

  return (
    <Box
      //  className={classes.componentContainer}
      mt={2}
      mb={4}
    >
      <FormWizard
        initialValues={{
          acceptedPrivacyPolicy: false,
          clientAccountDetails: {
            dateOfBirth: "",
            firstName: "",
            gender: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            comment: "",
          },
          employeeCombination: null,
          // cancellationPolicy: false,
          // readCoronaInfo: false,
          selectedEmployee: "",
          slotProposal: null,
          earliestArrivalTime: "",
          treatment: null,
          verificationCodeOTP: "",
        }}
        isSlot={false}
        handleSubmission={handleSubmission}
      >
        {
          /** Render props logic for FormWizard.
           *  Returned components that are booking steps are all "wrapped" in fragment and accessed as fragment children inside FormWizard.  */
          ({ goToNextPage, goToPreviousPage, page, lastPage }) => (
            <>
              <TreatmentSelect
                getAllTreatments={getAllAvailableAppointmentBookingTreatments}
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                orgUnitUUID={orgUnitUUID}
                validationFn={validateTreatmentSelection(t)}
              />
              <EmployeeSelectionStep
                page={page}
                lastPage={lastPage}
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                isDesktopDevice={isDesktopDevice}
                validationFn={validateEmployeeSelectionStep(t)}
              />

              {/** Desktop or mobile version for date time picker */}
              {isDesktopDevice ? (
                <DateTimePicker
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  validationFn={validateTreatmentStartTime(t)}
                />
              ) : (
                <DateTimePickerMobile
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  validationFn={validateTreatmentStartTime(t)}
                />
              )}
              <PatientForm
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                validationFn={validatePatientFormData(t, locale)}
              />
              <PhoneVerification
                validationFn={validatePhoneVerification(t)}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                isSlotReservation={false}
              />
              <AppointmentConfirmation
                validationFn={validateFormConfirmationAppointment(t)}
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
              />
            </>
          )
        }
      </FormWizard>
    </Box>
  );
};
