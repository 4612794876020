import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSetBookingParamContext } from "../components/BookingParamContext";

export const useSetACatParam = () => {
  const location = useLocation();
  const setACat = useSetBookingParamContext();
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const secret = search.get("aCat");
    if (secret) {
      setACat(secret);
    }
  }, []);
  return null;
};
