import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(232, 180, 184)",
    },
    secondary: {
      main: "#e50921",
    },
  },
});
