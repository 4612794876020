import axios from "axios";
import { FormValues } from "../type";
import { waitingQueueSlotAPI } from "../waitingQueueSlots/api";
import { institutionDetailsAndTreatmentsAPI } from "../institution/api";
import { timeSlotsAPI } from "../timeSlots/api";
import { phoneVerificationAPI, codeSubmissionAPI } from "../phoneVerification/api";
import { appointmentAPI, appointmentSlotAPI } from "../appointment/api";

export const baseURL = process.env.REACT_APP_PRODUCTION_API
  ? `${process.env.REACT_APP_PRODUCTION_API}/services/remotewaitingclientservice/api/public`
  : "https://dev1.rest.naboto.com/services/remotewaitingclientservice/api/public";

// Base URL for re-direction on successful submission
export const remoteWaitingURL = process.env.REACT_APP_REMOTE_WAITING_URL
  ? `https://${process.env.REACT_APP_REMOTE_WAITING_URL}`
  : "https://w.dev2.naboto.com";

const apiInstance = axios.create({ baseURL });

export const INSTITUTION_IDENTIFIER = process.env.REACT_APP_INSTITUTION_IDENTIFIER ?? "string";

export const callGetNextQueueSlot = (treatmentId: number | string, minutesToEarliestArrivalTime?: number) =>
  axios.get(`${baseURL}/treatment-types/${treatmentId}/next-waiting-queue-slot`, {
    params: { minutesToEarliestArrivalTime },
  });
export const callBookSlot = (
  treatmentId: number | string,
  formData: FormValues,
  minutesToEarliestArrivalTime?: number
) =>
  axios.post(`${baseURL}/treatment-types/${treatmentId}/remote-join-waiting-queue`, formData, {
    params: { minutesToEarliestArrivalTime },
  });
export const getTreatments = (institutionId: number | string) =>
  axios.get(`${baseURL}/${institutionId}/treatments-for-remote-check-in`);

export default {
  institutionDetailsAndTreatments: institutionDetailsAndTreatmentsAPI(apiInstance),
  waitingQueueSlots: waitingQueueSlotAPI(apiInstance),
  timeSlots: timeSlotsAPI(apiInstance),
  appointment: appointmentAPI(apiInstance),
  appointmentSlot: appointmentSlotAPI(apiInstance),
  phoneVerification: phoneVerificationAPI(apiInstance),
  codeSubmissionAPI: codeSubmissionAPI(apiInstance),
};
