import React from "react";
import { Container, Grid, Box, Link } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Link as RouterLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     backgroundColor: grey[900],
//     color: "white",
//     padding: theme.spacing(2),
//   },
//   logo: {
//     height: 30,
//     width: 150,
//     backgroundImage: `url(${logo})`,
//     backgroundSize: "contain",
//     backgroundRepeat: "no-repeat",
//     margin: theme.spacing(2, 0),
//   },
//   gridContainer: {
//     width: "auto",
//   },
// }));

export const Footer: React.FC = () => {
  // const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      p={2}
      sx={{
        backgroundColor: grey[900],
        color: "white",
      }}
    >
      <Container maxWidth="lg">
        <Box>
          <Grid item container direction="row" justifyContent="space-between">
            <Box
              sx={{
                width: "auto",
              }}
            >
              <Box
                my={"auto"}
                sx={{
                  fontSize: "2rem",
                  fontWeight: 800,
                }}
              >
                FragMichi
              </Box>
              <Box>{t("footer.infoText1")}</Box>
              <Box>{t("footer.infoText2")}</Box>
            </Box>
            <Grid item container direction="column" justifyContent="flex-end" sx={{ width: "auto" }}>
              <Box>
                <Link color="inherit" to="/privacy-policy" component={RouterLink}>
                  {t("footer.dataProtection")}
                </Link>
              </Box>
              <Box>
                <Link color="inherit" href="https://www.naboto.com/imprint" target="_blank">
                  {t("footer.impressum")}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
