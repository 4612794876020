import React from "react";
import { FieldProps, getIn } from "formik";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { FormValues } from "../FormWizard";
import { useTranslation } from "react-i18next";
import { Gender } from "../../modules/patients/types";

// const useStyle = makeStyles((theme) => ({
//   formStyle: {
//     minWidth: 130,
//   },
// }));

/**
 * Form component for gender selection
 */
export const GenderSelect = ({ field: { name, onBlur, onChange, value }, form }: FieldProps<string, FormValues>) => {
  const errors = getIn(form.errors, name);
  const touched = getIn(form.touched, name);

  // Selected value stored in Formik
  // const keyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
  //   switch (evt.key) {
  //     case "Enter":
  //       if (value !== "") handleSubmit();
  //       break;
  //     case "NumpadEnter":
  //       if (value !== "") handleSubmit();
  //       break;
  //     case "m":
  //       form.setFieldValue(name, Gender.);
  //       break;
  //     case "w":
  //       form.setFieldValue(name, "W");
  //       break;
  //     case "x":
  //       form.setFieldValue(name, "X");
  //       break;
  //     default:
  //       return;
  //   }
  // };
  const { t } = useTranslation();
  return (
    <FormControl fullWidth error={errors && touched} required sx={{ minWidth: "130px" }} variant="filled">
      <InputLabel id="gender-label" htmlFor="gender">
        {t("patientData.gender")}
      </InputLabel>
      <Select
        labelId="gender-label"
        id="gender"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={{ "data-testid": "gender-select" }}
      >
        <MenuItem value="" disabled>
          {t("patientData.gender")}
        </MenuItem>
        <MenuItem value={Gender.MALE}>{t("patientData.genders.male")}</MenuItem>
        <MenuItem value={Gender.FEMALE}>{t("patientData.genders.female")}</MenuItem>
        <MenuItem value={Gender.OTHER}>{t("patientData.genders.other")}</MenuItem>
        <MenuItem value={Gender.UNKNOWN}>{t("patientData.genders.unknown")}</MenuItem>
      </Select>
      <FormHelperText error={errors && touched}>{errors && touched ? errors : " "}</FormHelperText>
    </FormControl>
  );
};
