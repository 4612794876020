import React from "react";
import { AppBar, Toolbar, Grid, Box, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getInstitutionDetails } from "../../modules/institution/selectors";
import logo from "./assets/img/logo.png";

// const useStyles = makeStyles((theme) => ({
//   logo: {
//     height: 25,
//     width: 150,
//     backgroundImage: `url(${logo})`,
//     backgroundSize: "contain",
//     backgroundRepeat: "no-repeat",
//     marginRight: 5,
//     marginTop: 5,
//   },
// }));

export const TopBar = () => {
  // const classes = useStyles();
  const institutionDetails = useSelector(getInstitutionDetails);

  return (
    <AppBar color="default" position="static">
      <Toolbar component={Grid} container justifyContent="space-between" wrap="nowrap">
        <Grid item container alignItems="baseline">
          <Box
            my={"auto"}
            sx={{
              fontSize: "2rem",
              fontWeight: 800,
            }}
          >
            FragMichi
          </Box>
          {institutionDetails && (
            <Typography variant="h6" component={Grid} item alignItems="baseline">
              {institutionDetails.name}
            </Typography>
          )}
        </Grid>
        {/* <LanguageSelect /> */}
      </Toolbar>
    </AppBar>
  );
};
