import * as React from "react";

const BookingParamContext = React.createContext<string | null>(null);

const SetBookingParamContext = React.createContext<React.Dispatch<React.SetStateAction<string | null>> | undefined>(
  undefined
);

export const useBookingParamContext = () => {
  const context = React.useContext(BookingParamContext);

  if (context === undefined) {
    throw new Error("useBookingParamContext can only be used in BookingParamContext");
  }

  return context;
};

export const useSetBookingParamContext = () => {
  const context = React.useContext(SetBookingParamContext);
  if (context === undefined) {
    throw new Error("useBookingParamContext can only be used in BookingParamContext");
  }

  return context;
};

export const BookingParamProvider = ({ children }: { children: React.ReactElement }) => {
  const [aCatParam, setACatParam] = React.useState<null | string>(null);

  return (
    <BookingParamContext.Provider value={aCatParam}>
      <SetBookingParamContext.Provider value={setACatParam}>{children}</SetBookingParamContext.Provider>
    </BookingParamContext.Provider>
  );
};
