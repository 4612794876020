export interface PatientData {
  dateOfBirth: string;
  firstName: string;
  gender: Gender | "";
  lastName: string;
  phoneNumber: string;
  email: string;
  comment: string;
}

export enum Gender {
  FEMALE = "female",
  MALE = "male",
  OTHER = "other",
  UNKNOWN = "unknown",
}
