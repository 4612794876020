import { Gender } from "../patients/types";

export enum AppointmentSlotResponseCode {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  UNAVAILABLE = "UNAVAILABLE",
  ERROR_CREATING_CLIENT = "ERROR_CREATING_CLIENT",
  INTERNAL_ERROR = "INTERNAL_ERROR",
}

/**
 * Status of appointment after submission
 */
export interface AppointmentSlotState {
  /** Appointment slot response on successful booking */
  readonly appointmentSlot: AppointmentReserveResponse | null;
  readonly errorMessage: string | null;
  readonly isFetching: boolean;
  readonly isValid: boolean;
}

/**
 * Server description of appointment status after submission
 */
export interface AppointmentReserveResponse {
  appointmentPublicId: string;
  remoteWaitingToken: string;
  /** Server response, can take user back to reservation process */
  responseCode: AppointmentSlotResponseCode;
  /** Requested start time */
  startTime: string;
  treatmentTypePublicId: string;
}

/**
 * Object that is sent to the server for booking an appointment
 */
export interface AppointmentRequest {
  clientAccountForInstitutionUserDTO: {
    acceptedPrivacyPolicy: boolean;
    clientAccountDetails: {
      contactPoints: ContactPointDTO[];
      dateOfBirth: string;
      gender: Gender;
      names: HumanNameDTO[];
    };
  };
  comment: string | null;
  startTime: string | undefined | null;
  employeeCombination: string[] | null;
  treatmentTypePublicId: string | undefined;
  verificationCode: {
    otp: string | null;
    uuid: string | null;
  };
}

export interface ContactPointDTO {
  id?: number;
  period: Period | null;
  rank: number | null;
  system: ContactPointSystem | null;
  use: ContactPointUse | null;
  value: string | null;
}

export interface Period {
  end: string | null;
  start: string | null;
}

export enum ContactPointSystem {
  EMAIL = "email",
  FAX = "fax",
  OTHER = "other",
  PAGER = "pager",
  PHONE = "phone",
  URL = "url",
  SMS = "sms",
}

export enum ContactPointUse {
  HOME = "home",
  MOBILE = "mobile",
  OLD = "old",
  TEMP = "temp",
  WORK = "work",
}

export interface HumanNameDTO {
  family: string | null;
  given: string[] | null;
  id?: number;
  prefix: string[] | null;
  use: HumanNameUse;
  suffix: string[] | null;
  text: string | null;
  period: Period | null;
}

export enum HumanNameUse {
  ANONYMOUS = "anonymous",
  NICKNAME = "nickname",
  OFFICIAL = "official",
  OLD = "old",
  MAIDEN = "maiden",
  USUAL = "usual",
  TEMP = "temp",
}
