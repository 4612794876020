import { Box, Container, Grid, Typography } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import React from "react";

// const useStyles = makeStyles((theme) => ({
//   para: {
//     paddingTop: theme.spacing(2),
//   },
// }));

export const PrivacyPolicy = () => {
  // const classes = useStyles();
  return (
    <Grid container direction="column" style={{ height: "100%" }} wrap="nowrap">
      <Container maxWidth="md" style={{ flex: 1 }}>
        <Typography
          variant="h5"
          pt={2}
          // className={classes.para}
        >
          Datenschutzerklärung der Naboto Software GmbH
        </Typography>
        <Typography
          variant="subtitle2"
          // className={classes.para}
          pt={2}
        >
          Fassung 30.04.2023
        </Typography>
        <Typography
          //  className={classes.para}
          pt={2}
        >
          Diese Datenschutzerklärung soll Ihnen als Nutzer der von Naboto angebotenen Services darstellen, wie und zu
          welchem Zweck die von Ihnen im Registrierungsformular oder nachfolgend übermittelten Daten von Naboto
          verarbeitet werden.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
          variant="h6"
        >
          Rechtliche Grundlagen
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Die von Ihnen bekanntgegebenen Daten werden ausschließlich auf Grundlage und im Rahmen der gesetzlichen
          Regelungen (Datenschutzgrundverordnung – DSGVO, Datenschutzgesetz) verarbeitet.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Verarbeitung der Daten und Weitergabe an dritte Personen
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Die uns von Ihnen bekanntgegebenen personenbezogenen Daten, das sind insbesondere Ihr Geburtsdatum, Name und
          Geschlecht, werden von uns elektronisch gespeichert. Diese Daten werden für die Dauer des Bestehens des
          Vertrages gespeichert und in einer Frist von zwei Jahren ab Endigung des Nutzungsvertrages gelöscht.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Eine Weitergabe von personenbezogenen Daten erfolgt ausschließlich an die von Ihnen ausgewählten und
          teilnehmenden Gesundheitsdiensteanbieter (Ärzte, Ambulatorien).
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Eine Weitergabe an andere Personen wird von Naboto nicht vorgenommen
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Soweit wir von Ihnen für die Vereinbarung oder Änderung von Arztterminen angeforderte Unterlagen wie Auskünfte
          bzw. Bestätigungen über das Vorhandensein eines Herzschrittmachers oder von Diabetes erhalten, werden diese
          Daten unmittelbar nach Absolvierung des Arzttermines bei uns gelöscht. Dasselbe gilt für die uns von Ihnen
          bekanntgegebene Sozialversicherungsnummer.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
          variant="h6"
        >
          Verarbeitete Daten
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
          variant="subtitle2"
        >
          Allgemeine Daten
        </Typography>
        <Box>
          <ul>
            <li>
              Zugriffsdaten
              <ul>
                <li>Datum und Uhrzeit des Besuchs unseres Service</li>
                <li>Die Seite, von der das zugreifende System auf unsere Seite gelangt ist</li>
                <li>Bei der Nutzung aufgerufene Seiten</li>
                <li>Daten zur Sitzungsidentifizierung (Session ID)</li>
                <li>Verwendete Internet Protokoll-Adresse (IP-Adresse) des zugreifenden Hardwaresystems</li>
                <li>Browsertyp und -version des zugreifenden Hardwaresystems</li>
                <li>Gerätetyp des zugreifenden Hardwaresystems</li>
                <li>Betriebssystem und ähnliche technische Informationen des zugreifenden Hardwaresystems</li>
              </ul>
            </li>
          </ul>
        </Box>
        <Typography variant="subtitle2">Daten von PatientInnen/NutzerInnen</Typography>
        <Box>
          <ul>
            <li>
              Accountdaten
              <ul>
                <li>Login-/Benutzerkennung</li>
                <li>Passwort</li>
                <li>Bevorzugte Sprache</li>
              </ul>
            </li>
            <li>
              Personenstammdaten
              <ul>
                <li>Vorname</li>
                <li>Nachname und ggf. Geburtsname</li>
                <li>Geburtsdatum</li>
                <li>Geschlecht</li>
                <li>Titel</li>
                <li>Art und Name der Krankenkasse und ggf. Privatversicherung</li>
                <li>Ggf. Sozialversicherungsnummer</li>
              </ul>
            </li>
            <li>
              Kontaktdaten
              <ul>
                <li>Handynummer</li>
                <li>Email Adresse</li>
                <li>Adresse/Anschrift</li>
              </ul>
            </li>
            <li>
              Termin- und Besuchsdaten
              <ul>
                <li>Terminkategorie/Besuchsgrund</li>
                <li>Datum und Uhrzeit des Termins</li>
                <li>Datum und Uhrzeit zum Zeitpunkt der Buchung</li>
                <li>MedizindienstleisterIn/Institut/Örtlichkeit</li>
                <li>Dauer und Rüstzeiten</li>
                <li>Terminverlauf bzw. -historie</li>
                <li>Wartelistenposition</li>
                <li>Ggf. Kommentare</li>
                <li>Ggf. Foto bzw. Scan einer Zuweisung/Überweisung</li>
                <li>Ggf. von PatientInnen angegebene zukünftige Verfügbarkeiten für Termine</li>
              </ul>
            </li>
            <li>
              Termin- und Besuchs-Metadaten
              <ul>
                <li>No-Show-, Verschiebungs-, Absage- und Verspätungshistorie</li>
                <li>Untersuchungs-/Behandlungs-/Bearbeitungsdauern</li>
                <li>Datum und Uhrzeit zum Zeitpunkt der Buchung</li>
                <li>MedizindienstleisterIn/Institut/Örtlichkeit</li>
                <li>Dauer und Rüstzeiten</li>
                <li>Terminverlauf bzw. -historie</li>
                <li>Wartelistenposition</li>
                <li>Ggf. Foto bzw. Scan einer Zuweisung/Überweisung</li>
                <li>Ggf. von PatientInnen angegebene zukünftige Verfügbarkeiten für Termine</li>
              </ul>
            </li>
            <li>
              Metadaten
              <ul>
                <li>Geräte-Metadaten (Mobiltelefon-hersteller, Modell etc.)</li>
                <li>Bearbeitungs- bzw. Eingabedauern</li>
                <li>Standortdaten</li>
              </ul>
            </li>
          </ul>
        </Box>
        <Typography variant="h6">Verwendungszweck</Typography>
        <Typography
          pt={2}
          // className={classes.para}
        >
          Alle uns von Ihnen mitgeteilten Daten werden ausschließlich zu folgenden Zwecken verwendet: Durchführung und
          Abwicklung der Buchung von Untersuchungs- oder Behandlungsterminen bei den teilnehmenden Ärzten sowie
          Optimierung der von Naboto verwendeten Software, um diese Vorgänge kontinuierlich zu verbessern.
        </Typography>
        <Typography
          pt={2}
          // className={classes.para}
        >
          Detaillierte Auflistung der Verwendungszwecke:
        </Typography>
        <Box>
          <ul>
            <li>
              Verarbeitungszweck: PatientInnenregistrierung und Verifizierung
              <ul>
                <li>
                  Daten
                  <ul>
                    <li>Accountdaten</li>
                    <li>Personenstammdaten</li>
                    <li>Kontaktdaten</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Verarbeitungszweck: Online-Terminverwaltung (Buchung, Verschiebung, Absage bzw. Löschung etc.) bei
              Medizindienstleister-Innen, offline-Termin-verwaltung durch MedizindienstleisterIn bzw. dessen/deren
              Personal bei registrierten PatientInnen sowie Abwickeln von registrierten PatientInnen OHNE Termin
              („Walk-ins“) (Warteliste, Nummernziehen, virtuelles Nummernziehen) inkl. Optimierung der Warteliste (z.B.
              Vorziehen von Kindern, SchmerzpatientInnen etc.)
              <ul>
                <li>
                  Daten
                  <ul>
                    <li>Accountdaten</li>
                    <li>Personenstammdaten</li>
                    <li>Kontaktdaten</li>
                    <li>Termin- und Besuchsdaten</li>
                    <li>Termin- und Besuchs-Metadaten</li>
                    <li>Metadaten</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Verarbeitungszweck: Optimierung der Terminvergabe um Wartezeiten für PatientInnen vor Ort und die
              Wartezeit auf Termin zu reduzieren, Optimierung der Auslastung von MedizindienstleisterInnen nach Ihren
              individuellen Präferenzen (inkl. Berechnung individueller Wartezeiten, No-Show Wahrscheinlichkeiten und
              Behandlungsdauern) sowie Versenden von Terminerinnerungen und Informationen zu Wartezeiten,
              Wartelistenpositionen und Terminen (registrierte PatientInnen).
              <ul>
                <li>
                  Daten
                  <ul>
                    <li>Accountdaten</li>
                    <li>Kontaktdaten</li>
                    <li>Personenstammdaten</li>
                    <li>Termin- und Besuchsdaten</li>
                    <li>Termin- und Besuchs-Metadaten</li>
                    <li>Metadaten</li>
                    <li>Institutsdaten</li>
                    <li>Termin- und Besuchs-Metadaten (MedizindienstleisterIn)</li>
                    <li>Konfigurationsdaten</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Verarbeitungszweck: Eintragen auf elektronischer Terminwarteliste sowie Versenden von Benachrichtigungen
              bei freigewordenen Terminen
              <ul>
                <li>
                  Daten
                  <ul>
                    <li>Accountdaten</li>
                    <li>Personenstammdaten</li>
                    <li>Kontaktdaten</li>
                    <li>Termin- und Besuchsdaten</li>
                    <li>Termin- und Besuchs-Metadaten</li>
                    <li>Metadaten</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Verarbeitungszweck: Schutz vor Missbrauch und sonstiger unbefugter Nutzung bei Anmeldung zu unseren bzw.
              Nutzung unserer Services.
              <ul>
                <li>
                  Daten
                  <ul>
                    <li>Zugriffsdaten</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </Box>
        <Typography variant="h6">Webseite</Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Soweit Sie die Dienste von Naboto auf der Webseite www.naboto.com nutzen, werden sogenannte Cookies auf dem
          von Ihnen verwendeten Endgerät (Smartphone, Tablet, etc.) gesetzt. Dabei handelt es sich um Textdateien, die
          eine Analyse der Benutzung der Webseite ermöglichen. Die dadurch gewonnenen Informationen werden nicht an
          dritte Personen weitergegeben. Die IP-Adresse des von Ihnen verwendeten Gerätes wird anonymisiert, sodass Sie
          anonym bleiben.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Ausdrücklich möchten wir Sie darauf hinweisen, dass Sie das Setzen von Cookies auf dem von Ihnen verwendeten
          Browser in den dort vorgesehenen Einstellungen verhindern können. Dies kann allerdings dazu führen, dass eine
          Nutzung der Webseite nicht oder nur eingeschränkt möglich ist.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Um unseren Webauftritt laufend zu verbessern, nutzen wir den Dienst Google Analytics der Google LLC (1600
          Amphitheatre Parkway Mountain View, CA 94043, USA). Dabei ist Google Analytics von uns so eingestellt, dass
          IP-Adressen anonymisiert werden und damit kein Personenbezug erfolgen kann.
        </Typography>
        <Typography
          pt={2}
          // className={classes.para}
        >
          Die Nutzung des Dienstes beruht auf der Datenschutzerklärung von Google, welche unter folgendem Link abgerufen
          werden kann: https://www.google.com/intl/de/policies/privacy/. Google ist nach dem EU-US Datenschutzschild
          („Privacy Shield“) zertifiziert und entspricht damit den rechtlichen Bedingungen als Dienstleister aus einem
          Drittland nach EU-DSGVO.
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Sie können die Erfassung und Verarbeitung der auf Ihre Nutzung der Webseite bezogenen Daten durch Google
          verhindern, indem Sie ein entsprechendes Browser-Plugin installieren, welches Sie unter folgendem Link
          herunterladen können: https://tools.google.com/dlpage/gaoptout?hl=de
        </Typography>
        <Typography
          variant="h6"
          // className={classes.para}
          pt={2}
        >
          Seiten von Fremdanbietern
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Soweit auf unserer Webseite links zu Webseiten dritter Personen angeboten werden, übernehmen wir keine Haftung
          für die Rechtmäßigkeit der auf diesen Seiten angebotenen Inhalte.{" "}
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Links zu sozialen Netzwerken (Facebook, Twitter, etc.) sind auf unserer Webseite nicht eingebunden.{" "}
        </Typography>
        <Typography
          variant="h6"
          // className={classes.para}
          pt={2}
        >
          Datensicherheit
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Naboto trifft laufend Maßnahmen, um die Sicherheit der bei ihr verarbeiteten Daten zu wahren. Im Falle eines
          databreach (Datenleck) werden wir sowohl alle Nutzer als auch die zuständige Behörde (Datenschutzbehörde)
          möglichst umgehend informieren und die für die Behebung erforderlichen Maßnahmen treffen.
        </Typography>
        <Typography
          variant="h6"
          // className={classes.para}
          pt={2}
        >
          Datenschutzrechtliche Hinweise
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Sie sind berechtigt von uns Auskunft darüber zu verlangen, welche Daten wir von Ihnen verarbeiten. Ebenso sind
          Sie berechtigt, eine Richtigstellung, Einschränkungen oder die Löschung dieser Daten zu verlangen. Für den
          Fall, dass Sie vermeinen, in Ihren Datenschutz-Rechten verletzt worden zu sein, steht Ihnen das Recht der
          Beschwerde an die Aufsichtsbehörde zu. Das ist in Österreich die Datenschutzbehörde.
        </Typography>
        <Typography
          variant="h6"
          // className={classes.para}
          pt={2}
        >
          Änderungen
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Naboto ist berechtigt diese Datenschutzerklärung jederzeit zu ändern. Im Falle einer Änderung wird Ihnen diese
          mitgeteilt. Die geänderte Erklärung tritt ab dem Zeitpunkt der Mitteilung in Kraft. Frühere Versionen der
          Datenschutzerklärung werden archiviert und sind jederzeit nachvollziehbar.{" "}
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
          variant="h6"
        >
          Verantwortlicher gemäß Art. 4 DSGVO
        </Typography>
        <Typography
          //  className={classes.para}
          pt={2}
        >
          Naboto Software GmbH, Trazerberggasse 79, 1130 Wien Email: office@naboto.com
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
          variant="h6"
        >
          Zuständige Aufsichtsbehörde
        </Typography>
        <Typography
          // className={classes.para}
          pt={2}
        >
          Österreichische Datenschutzbehörde, Wickenburggasse 8, 1080 Wien
        </Typography>
      </Container>
    </Grid>
  );
};
