import { ContactPointDTO, HumanNameDTO } from "../appointment/types";
import { Treatment } from "../institution/types";
import { Gender } from "../patients/types";

export enum CheckInStatusCode {
  CREATED = "CREATED",
  INVALID_TREATMENT_TYPE = "INVALID_TREATMENT_TYPE",
  ERROR_CREATING_CLIENT_ACCOUNT = "ERROR_CREATING_CLIENT_ACCOUNT",
  ERROR_CREATING_WAITING_QUEUE_SLOT = "ERROR_CREATING_WAITING_QUEUE_SLOT",
  NO_WAITING_QUEUE_SLOT_AVAILABLE = "NO_WAITING_QUEUE_SLOT_AVAILABLE",
  OUT_OF_OPENING_HOURS = "OUT_OF_OPENING_HOURS",
  REMOTE_CHECK_IN_CLOSED = "REMOTE_CHECK_IN_CLOSED",
}

export enum CheckInAvailabilityStatusCode {
  AVAILABLE = "AVAILABLE",
  NO_SLOT_AVAILABLE = "NO_SLOT_AVAILABLE",
  REMOTE_CHECK_IN_CLOSED = "REMOTE_CHECK_IN_CLOSED",
  OUT_OF_OPENING_HOURS = "OUT_OF_OPENING_HOURS",
  INVALID_TREATMENT_TYPE = "INVALID_TREATMENT_TYPE",
}

/**
 * Waiting queue slot received from server and is offered to the client
 */
export interface WaitingQueueSlot {
  checkInAvailabilityStatusCode: CheckInAvailabilityStatusCode;
  /** ISO string of the earliest possible start time for a walk-in */
  earliestStartTime: string;
  /** ISO string of the lastest possible start time for a walk-in */
  latestStartTime: string;
  /** ID of the treatment type we requested the slot for */
  treatmentTypeId: number;
}

/**
 * Data sent to server on requesting next possible waiting queue slot
 */
export interface WaitingQueueSlotFetchPayload {
  /** Selected employees */
  employeeCombination: string[] | null;
  earliestPossibleArrivalTime: string | undefined;
  treatmentPublicId: string;
}

/**
 * Data sent to server on requesting next possible waiting queue slot
 */
export interface WaitingQueueSlotFetchOptions {
  /** Selected employees */
  employeeCombination: string[] | null;
  earliestPossibleArrivalTime: string | undefined;
  minutesToEarliestArrivalTime?: number | undefined;
  treatmentPublicId: string;
}

export interface WaitingQueueSlotState {
  error: string | null;
  /**
   * Waiting queue slot object. Initially null - is requested after patient has selected earliestArrivalTime
   */
  waitingQueueSlot: WaitingQueueSlot | null;
  isFetching: boolean;
  /**
   * Time stamp created on successful fetch.
   */
  lastFetch: number | null;
  slotIsValid: boolean;
}

/**
 * Data sent to server on slot reservation submission
 */
export interface WaitingQueueReserveSlotOptions {
  formData: FormData;
  earliestPossibleArrivalTime: string | undefined;
  minutesToEarliestArrivalTime?: number | undefined;
}

interface FormData {
  acceptedPrivacyPolicy: boolean;
  clientAccountDetails: {
    contactPoints: ContactPointDTO[];
    dateOfBirth: string;
    gender: Gender | "";
    names: HumanNameDTO[];
  };
  treatment: null | Treatment;
  comment: string | null;
  employeeCombination: string[] | null;
  verificationCode: {
    otp: string | null;
    uuid: string | null;
  };
}

/**
 * Server response upon slot submission
 */
export interface WaitingQueueReserveSlotResponse {
  remoteWaitingToken: string;
  publicId: string;
  checkInStatusCode: CheckInStatusCode;
}
